import {makeAutoObservable} from "mobx";

export class CharacterTab{
    currentTab: string = "property";
    constructor() {
        makeAutoObservable(this);
    }
    UpdateCurrentTab(tab:string){
        this.currentTab = tab;
    }
}
